<template>
    <div>
        <div class="mt-1">
            <Summary show-message="custom" v-if="isSigned"></Summary>
            <v-container v-if="!loading && auth">
                <div class="col-md-12  col-sm-12  general-body  text-center  text-md-left" v-if="!isSigned">
                    <p>
                        <strong>{{ ownerName }}</strong>
                        {{ $t("send_you_a_request_to_sing_doc") }}
                    </p>
                </div>

                <div class="document-info  text-md-left  pa-1" v-if="!isSigned">
                    <p>
                        <strong>{{ $t("title") }}:</strong> {{ title }}
                    </p>
                    <p v-if="date !== null">
                        <strong>{{ $t("sign_before") }}:</strong> {{ moment(date).format("DD/MM/YYYY") }}
                    </p>
                    <p class="font-weight-regular" v-html="comments"></p>
                </div>
                <div v-if="status === 'SIGNED' && !isSigned">
                    <v-alert text color="#4CAF50" border="left" class="pa-2">
                        <v-icon color="#4CAF50">mdi-checkbox-marked-circle</v-icon>
                        <strong>{{ $t("this_request_has_already_been_signed") }} </strong>
                    </v-alert>
                </div>
                <div v-if="expired && !isSigned">
                    <v-alert text color="#4CAF50" border="left" class="pa-2">
                        <v-icon color="#4CAF50">mdi-checkbox-marked-circle</v-icon>
                        <strong>{{ $t("this_request_has_expired") }} </strong>
                    </v-alert>
                </div>
            </v-container>
            <DefaultLoading v-if="loading" :loading="loading" />
            <AuthFail v-if="!auth && !loading" />
        </div>


        <v-flex>
            <HomeFooter v-if="!loading && auth && !isSigned && !expired" :loading="loading" :isSigned="isSigned" :Token="this.$route.query.token" :typeOfId="typeOfId" />
            <!--             <HomeFooter v-if="!loading && auth && !isSigned" :loading="loading" :hasSignerForm="hasSignerForm"
                :hasPendingSignerForm="hasPendingSignerForm" :hasAttachments="hasAttachments" /> -->
        </v-flex>
    </div>
</template>

<script>
import AuthFail from "../components/shared/AuthFail.component";
import HomeFooter from "../components/shared/HomeFooter.component";
import DefaultLoading from "../components/shared/DefaultLoading.component";
import Summary from "../components/Summary.component";
import moment from "moment";
import { mapState } from 'vuex';

export default {
    name: "general-information",
    components: {
        AuthFail,
        DefaultLoading,
        HomeFooter,
        Summary
    },
    data: function () {
        return {
            pdfIcon: require("../assets/pdf.svg"),
            terms_accepted: null,
            requestId: "",
            token: "",
            agreed: false,
            document: null,
            signerName: "",
            ownerName: "",
            loading: true,
            authCitizenId: null,
            title: "",
            date: "",
            comments: "",
            requestDetails: null,
            transactionId: "",
            signatureRequestID: "",
            signatureRequestToken: "",
            hasSignerForm: false,
            hasPendingSignerForm: false,
            hasAttachments: false,
            AuthID: "",
            typeOfId: "",
            expired: false,
            dueDateExclusionary: true
        };
    },

    created: function () {
        this.moment = moment;
    },

    mounted() {
        this.requestId = this.$route.params.id;
        this.token = this.$route.query.token;
        this.authCitizenId = this.$route.query.auth || null;
        localStorage.setItem('auth_citizen_id', this.authCitizenId);

        if (this.$route.query.noreject === 'true') {
            localStorage.setItem('noreject', 'true');
        }
        else {
            localStorage.removeItem('noreject');
        }

        this.$store.dispatch("common/addAuthId", "");
        this.$nextTick(() => {
            if (this.requestId && this.token) {
                this.checkAuth();
            }
        });
    },

    computed: {
        auth() {
            return this.$store.state.common.auth;
        },
        status() {
            return this.$store.state.common.status;
        },
        authId() {
            return this.$store.state.common.authId;
        },
        isSigned() {
            if (this.authCitizenId) {
                return this.status == 'SIGNED' && this.authId ? true : false;
            } else {
                return this.status == 'SIGNED' ? true : false;
            }
        },
        ...mapState({
            SignRequestID: state => state.common.requestId,
        })
    },


    methods: {
        checkAuth() {
            this.loadRequestData();
        },
        loadRequestData() {
            this.loading = true;
            console.log(' checking id');
            this.$store
                .dispatch("common/getRequestData", {
                    token: this.$route.query.token,
                    requestId: this.requestId,
                    authId: this.authId,
                    authCitizenId: this.authCitizenId,
                })
                .then(response => {
                    console.log('Respuesta del servidor:', response.data);
                    const signRequest = response.data;
                    this.loading = false;
                    if (signRequest.id == this.$route.params.id) {
                        this.document = signRequest.signatureTransaction.description;
                        this.signerName = signRequest.signerName;
                        this.ownerName = signRequest.signatureTransaction.ownerName;
                        this.title = signRequest.signatureTransaction.description ? signRequest.signatureTransaction.description : "";
                        this.comments = signRequest.signatureTransaction.comments;
                        this.date = signRequest.signatureTransaction.dueDate;
                        this.expired = signRequest.signatureTransaction.expired;
                        this.dueDateExclusionary = signRequest.signatureTransaction.dueDateExclusionary;
                        this.transactionId = signRequest.signatureTransaction.id;
                        this.typeOfId = signRequest.typeOfId;
                        // this.loadDocumentData();
                        console.log(this.$store.state);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                });
        },       



    }
};
</script>